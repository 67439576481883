<template>
 <!-- 客房事件页面 -->
 <!-- 头部选择器 -->
 <div class="my-box">
  <el-card shadow="always" class="my-card topS">
   <el-form label-width="50px">
    <el-row :gutter="10" style="margin-bottom: 0">
     <el-col :span="4">
      <el-form-item label="门店">
       <el-input v-model.trim="searchForm.first" clearable></el-input>
      </el-form-item>
     </el-col>
       <el-col :span="3">
      <el-form-item label="推送类型" label-width="80px">
       <!-- <el-input v-model.trim="searchForm.keyword1" clearable></el-input> -->
       <el-select v-model="searchForm.keyword1" clearable filterable placeholder="请选择">
        <el-option v-for="(item,index) in keywordList" :key="index" :label="item" :value="item"></el-option>
       </el-select>
       
      </el-form-item>
     </el-col>
     <el-col :span="3">
      <el-form-item label="推送结果" label-width="80px">
       <!-- <el-input v-model.trim="searchForm.keyword1" clearable></el-input> -->
       <el-select v-model="searchForm.isSend" clearable filterable placeholder="请选择">
        <el-option v-for="(item,index) in typeLists.isSends" :key="index" :label="item.label" :value="item.value"></el-option>
       </el-select>
       
      </el-form-item>
     </el-col>
     <el-col :span="3">
      <el-form-item label="姓名">
       <el-input v-model.trim="searchForm.employeeName" clearable></el-input>
      </el-form-item>
     </el-col>
     <el-col :span="3">
      <el-form-item label="职位">
       <el-select v-model="searchForm.position" clearable filterable placeholder="请选择">
        <el-option v-for="(item,index) in typeLists.cardTypes" :key="index" :label="item.label" :value="item.value"></el-option>
       </el-select>
      </el-form-item>
     </el-col>
     <el-col :span="5">
      <el-form-item label="触发时间" label-width="80px">
       <el-date-picker v-model="searchForm.times" type="daterange" :default-time="defaultTime" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
      </el-form-item>
     </el-col>
    

     <el-col :span="3">
      <div class="grid-content reset-button">
       <el-button size="mini" type="primary" @click="handleSearch">查询</el-button>
       <el-button size="mini" @click="reset">重置</el-button>
      </div>
     </el-col>
    </el-row>

   </el-form>
  </el-card>
  <el-card>
   <el-table :data="tablist" v-loading="loading" id="tab" :height="height" style="width: 100%">
    <el-table-column v-for="(item,i) in execlTab" :key="i" :width="item.width" :prop="item.value" :label="item.label">
     <template slot-scope="scope">
      <div v-if="item.solt_TEXT">
       <div v-if="item.type=='icon'">
         <el-tag   v-if="showLabelText(scope.row[item.value],typeLists[item.solt_TEXT],item.type)" :type="showLabelText(scope.row[item.value],typeLists[item.solt_TEXT],item.type).type">
            <i :class="showLabelText(scope.row[item.value],typeLists[item.solt_TEXT],item.type).icon"></i>
         </el-tag >
       </div>
       <div v-else>
       {{showLabelText(scope.row[item.value],typeLists[item.solt_TEXT])}}
       </div>
      </div>
      <div v-else>
       {{scope.row[item.value]}}
      </div>
     </template>
    </el-table-column>
   </el-table>
   <div class="page">
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="listQuery.pageNum" :page-sizes="[10, 20, 50, 100]" :page-size="listQuery.pageSize" layout="prev, pager, next, sizes, total, jumper" :total="total">
    </el-pagination>
   </div>
  </el-card>
 </div>
</template>
<script>
import { export_json_to_excel } from "@/vendor/Export2Excel.js";
import {
 getLongDateTime,
 secondGetLongTime,
 showLabel,
 labelShowDate,
} from "@/api/common.js";
import {
 getMessageLog,
 getBrandSelect,
 getHotelSelect,
 makeRoomOpsRate,
} from "@/api";
const typeLists = {
 isSends: [
  {
   value: 0,
   type:"danger",
   icon:"el-icon-close",
   label: "推送失败",
  },
  {
   value: 1,
    type:"success",
   icon:"el-icon-check",
   label: "推送成功",
  },
 ],
};
export default {
 data() {
  return {
   height: null,
   tablist: [],
   keywordList:[
     "每日房态报表",
     "无卡取电信息报表",
     "设备离线信息报表",
     "房间异常信息报表"
   ],
   execlTab: [
    {
     label: "门店",
     value: "first",
     width: 280,
    },
    // {
    //  label: "openId",
    //  value: "openId",
    // },
    {
     label: "职位",
     value: "position",
     solt_TEXT: "cardTypes",
    },
    {
     label: "姓名",
     value: "employeeName",
    },

    {
     label: "推送时间",
     value: "createTime",
    },
    {
     label: "推送类型",
     value: "keyword1",
    },
    {
     label: "推送结果",
     value: "isSend",
     type:"icon",
     solt_TEXT: "isSends",
    },
   ],
   brandSelectData: null,
   hotelSelectData: null,
   loading: false,
   // 查询的数据
   searchForm: {
    first: null,
    isSend: null,
    times: null,
    employeeName: null,
    position: null,
   },
   total: 0,
   listQuery: {
    pageNum: 1,
    pageSize: 10,
   },
   defaultTime: ["00:00:00", "23:59:59"],
  };
 },

 beforeMount() {
  let h = document.documentElement.clientHeight || document.body.clientHeight;
  this.height = h - 240;
 },
 computed: {
  typeLists() {
   let obj = typeLists;
   obj.cardTypes = this.$store.state.cardTypes;
   return obj;
  },
  showexport() {
   return this.$store.state.roleMenu.includes("makeRoom:export");
  },
 },

 created() {
  this.getList();
  this.initialize();
 },
 methods: {
  startDisabled(t) {
   if (this.searchForm.endDate && t)
    return new Date(t).getTime() > new Date(this.searchForm.endDate).getTime();
   return false;
  },
  endDisabled(t) {
   if (this.searchForm.startDate && t)
    return (
     new Date(t).getTime() < new Date(this.searchForm.startDate).getTime()
    );
   return false;
  },
  // 根据值显示信息
  showLabelText(val, list,type) {
   return showLabel(val, list,type);
  },

  getDay(val) {
   return labelShowDate(val);
  },
  // 时长
  showLongTimes(val) {
   return secondGetLongTime(val);
  },

  initialize() {
   getBrandSelect().then((res) => {
    if (res.status === 200) {
     this.brandSelectData = res.data.data;
    }
   });
  },
  getSearchData() {
   this.listQuery.pageNum = 1;
   for (let key in this.searchForm) {
    // if (this.searchForm[key] || this.searchForm[key] === 0) {

    if (key == "times") {
     if (this.searchForm[key]) {
      this.listQuery.start = this.searchForm[key][0];
      this.listQuery.end = this.searchForm[key][1];
     } else {
      this.listQuery.start = null;
      this.listQuery.end = null;
     }
    } else {
     if (this.searchForm[key] === "") {
      this.listQuery[key] = undefined;
     } else {
      this.listQuery[key] = this.searchForm[key];
     }
    }
    // }else{
    //   this.listQuery[key] =undefined
    // }
   }
  },
  // 获取门店下拉框 -- 2
  selectOne(id) {
   this.searchForm.hotelId = null;
   this.hotelSelectData = null;
   if (!id) return;
   getHotelSelect(id).then((res) => {
    console.log(res, "-----------");
    if (res.data.data) {
     this.hotelSelectData = res.data.data;
    } else {
     this.$message({
      message: "该品牌下没有门店数据",
      type: "warning",
     });
    }
   });
  },
  // 搜索
  handleSearch() {
   this.getSearchData();
   this.getList();
  },
  // 重置
  reset() {
   //  this.searchForm = {
   //   first: null,
   //   keyword1: null,
   //   times:null
   //  };
   ["first", "isSend", "times", "employeeName", "position"].forEach(
    (key) => (this.searchForm[key] = null)
   );
   this.getSearchData();
   this.getList();
  },
  handleSizeChange(val) {
   this.listQuery.pageSize = val;
   this.getList();
  },
  handleCurrentChange(val) {
   this.listQuery.pageNum = val;
   this.getList();
  },
  // 列表数据
  getList() {
   this.loading = true;
   console.log(this.listQuery);
   getMessageLog(this.listQuery)
    .then((res) => {
     this.loading = false;
     if (res.data.code == "000000") {
      this.tablist = res.data.data.rows;
      this.total = res.data.data.total;
     } else {
      this.$message.warning(res.data.message);
     }
    })
    .catch((err) => {
     this.loading = false;
    });
  },
 },
};
</script>
<style lang="scss" scoped >
.el-tag i {
  width: 16px !important;
    height: 16px !important;
    font-size: 16px !important;
    position: inherit !important;
}
.leftInput {
 width: 80%;
}
.my-box {
 .my-card {
  margin-bottom: 10px;
 }

 .el-row {
  margin-bottom: 10px;
  &:last-child {
   margin-bottom: 0;
  }
 }

 .grid-content {
  border-radius: 4px;
  height: 36px;
  white-space: nowrap;
  .el-input__inner {
   max-width: 190px;
  }
  span {
   font-size: 14px;
  }
 }

}
</style>


